import React from 'react';
import {Col, Row} from "reactstrap";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquareXTwitter, faSquareInstagram} from "@fortawesome/free-brands-svg-icons";
import './Footer.scss';

export default function Footer() {
  return (
    <>
    <Row className="footer-wrapper">
      <Col xs={12} className="mt-3">
        <Row>
          <Col xs={12}>
            <div className="ts-logo-wrapper text-center">
              <img src={'/ticker-study-logo-bw.png'} alt={'Ticker Study Logotype'}/>
            </div>
          </Col>
        </Row>
        <Row className="mt-2 site-links">
          <Col xs={12} sm={4} className="text-center text-sm-end">
            <Link to="/">Home</Link>
          </Col>
          <Col xs={12} sm={4} className="text-center">
            <Link to="/sic">Industries</Link>
          </Col>
          <Col xs={12} sm={4} className="text-center text-sm-start">
            <Link to="/about">About</Link>
          </Col>
        </Row>
        <Row className="mt-3 mb-4">
          <Col xs={12} className='text-center social-links'>
            <a href="https://x.com/tickerstudy" className="me-3" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faSquareXTwitter} />
            </a>
            <a href="https://instagram.com/tickerstudy" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faSquareInstagram} />
            </a>
          </Col>
        </Row>
      </Col>
    </Row>
    </>
  );
}
