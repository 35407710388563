import React, {useEffect} from 'react';
import { useLocation } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

import Header from "../Header/header";
import Footer from "../../Footer/Footer";
import Routes from "./routes";

import './Layout.scss';
import {initAnalytics, trackPageView} from "../../../analytics";


export default function Layout() {
  initAnalytics();
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname + location.search)
  }, [location]);

  return (
    <div className="app-container">
      <Container fluid className={'navigation-wrapper'}>
        <Container>
          <Row>
            <Header />
          </Row>
        </Container>
      </Container>

      <Container className="page-content">
        <Row>
          <Col>
            <div className="page-wrapper">
              <Routes/>
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid={true} className={'page-footer'}>
        <Container>
          <Row>
            <Col>
              <Footer />
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}
